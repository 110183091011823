.app {
  padding-bottom: 50px;

  .top-menu .top-menu-link.active {
    color: gray;
    pointer-events: none;
  }

  .result-epic-header {
    display: flex;
    align-items: center;
  }

  .result-epic-duration {
    display: inline-block;
    margin-left: 12px;
    font-size: 12px;
  }

  &--light *::selection {
    color: white;
    background: #835500;
  }

  &--dark *::selection {
    color: black;
    background: #AB7F49;
  }
}

.login-wrapper {
  max-width: 350px;
  margin: 0 auto;
}

.MuiAccordionDetails-root {
  margin-left: 16px;
  margin-right: 16px;
}
